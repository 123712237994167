import React, { useState, useEffect } from 'react';
import './portfolio.css';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, CircularProgress } from '@mui/material'; // Import CircularProgress from MUI
import AlertDialog from '../MUI Components/alertDialog';
import Snackbar from '@mui/material/Snackbar';
import ImageDetailsDialog from './addDialog';
import { Draggable, Droppable } from 'react-drag-and-drop';
import VisibilityIcon from '@mui/icons-material/Visibility';

// var selectedImage = null;
var isUseEffectCalled = false;
const Portfolio = ({ title, section_id, hasDescription, hasDate, isNewsAndUpdate, hasDesignation, isBrandCommunication, hasMiniImage, hasDescriptionTitle }) => {
  var [imagesbase64, setImagesbase64] = useState([]);
  var [images, setImages] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [loading, setLoading] = useState(true); // State for loading indicator
  var [selectedImage, setSelectedImage] = useState(null);

  const handleOrder = (orderList) => {
    const raw = JSON.stringify({
      "section_id": section_id,
      "orderList": orderList
    });

    const requestOptions = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${sessionStorage.getItem('accessToken')}`,
        "Content-Type": "application/json"
      },
      body: raw,
      redirect: "follow"
    };

    fetch("https://thestoryloft.in/api/orders/" + section_id, requestOptions)
      .then((response) => {
        console.log(response, "response")
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const onDrop = (data, targetIndex) => {
    let selectedIndex = Number(data.image);
    console.log(selectedIndex, targetIndex, "index")
    const updatedBase64Images = [...imagesbase64];
    updatedBase64Images.splice(selectedIndex > targetIndex ? targetIndex : targetIndex + 1, 0, updatedBase64Images[selectedIndex]);
    updatedBase64Images.splice(selectedIndex > targetIndex ? selectedIndex + 1 : selectedIndex, 1);

    const updatedImages = [...images];
    updatedImages.splice(selectedIndex > targetIndex ? targetIndex : targetIndex + 1, 0, updatedImages[selectedIndex]);
    updatedImages.splice(selectedIndex > targetIndex ? selectedIndex + 1 : selectedIndex, 1);

    setImagesbase64(updatedBase64Images);
    setImages(updatedImages);
    handleOrder(updatedImages.map(val => val["image_id"]).join(","))
  };

  const fetchAllImages = () => {
    setLoading(true);
    let requestOptions = {
      method: "GET",
      redirect: "follow"
    };
    //get image order
    fetch("https://thestoryloft.in/api/orders/" + section_id, requestOptions)
      .then((response) => response.json())
      .then((orderResult) => {
        console.log(orderResult[0].orderList, "orderList");
        //Get Images
        fetch(`https://thestoryloft.in/api/images?section_id=${section_id}&refresh=` + Number(Math.random()).toFixed(1), requestOptions)
          .then((response) => response.json())
          .then(async (result) => {
            let sortedImages = result.map(val => {
              val["image_order"] = orderResult[0].orderList.split(",").indexOf(val.image_id.toString());
              if (val["image_order"] == -1) val["image_order"] = 10000;
              return val;
            }).sort((a, b) => a.image_order - b.image_order);

            // const fetchPromises = sortedImages.map((image, i) => {
            //   let requestOptions = {
            //     method: "GET",
            //     redirect: "follow"
            //   };

            //   return fetch(`https://thestoryloft.in/api/base64image?url=${encodeURIComponent(image.image_url)}`, requestOptions)
            //     .then((response) => response.text())
            //     .then((base64Image) => {
            //       return base64Image;
            //     })
            //     .catch((error) => console.error(error));
            // });

            // const base64Images = await Promise.all(fetchPromises);
            setImagesbase64(sortedImages.map(val => val.image_url));
            setImages(sortedImages);
            setLoading(false); // Set loading to false after data is fetched
          })
          .catch((error) => {
            console.error(error);
            setLoading(false); // Set loading to false on error as well
          });
      })
      .catch((error) => console.error(error))

  };

  useEffect(() => {
    if (!isUseEffectCalled) {
      isUseEffectCalled = true;
      fetchAllImages();
    }
    return () => {
      isUseEffectCalled = false;
    }
  }, []);

  const handleOpenDialog = (image) => {
    setSelectedImage(image);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedImage(null);
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
    setSelectedImage(null);
  };

  const handleDelete = () => {
    setLoading(true);
    setOpenDialog(false);
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${sessionStorage.getItem('accessToken')}`
      },
      redirect: "follow"
    };

    fetch(`https://thestoryloft.in/api/images/${selectedImage["section_id"]}/${selectedImage["image_id"]}/${selectedImage["image_url"].split("amazonaws.com/")[1]}`, requestOptions)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          fetchAllImages();
          setSnackMessage("Deletion successful!");
        } else {
          setSnackMessage("Deletion failed!");
        }
        setOpenSnackbar(true);
      })
      .catch((error) => {
        console.error(error);
        setSnackMessage("Deletion failed!");
        setOpenSnackbar(true);
      });
  };

  const handleAdd = async (image, imageName, selectedImage, imageDescription, imageDate,isImageChanged, designation, miniImage, descriptionTitle) => {

    //1.get secure URL
    setLoading(true);
    let requestOptions = {
      method: "GET",
      redirect: "follow"
    };
    if (isBrandCommunication) {
      fetch("https://thestoryloft.in/api/s3Url", requestOptions)
        .then((response) => response.json())
        .then((securedURL) => {
          console.log(securedURL, "securedURL");
          // 2.Upload into s3
          fetch(securedURL.url, {
            method: "PUT",
            body: image,
            headers: {
              'Content-Type': image.type
            }
          })
            .then(resp => {
              let imageUrl = securedURL.url.split("?")[0];
              fetch("https://thestoryloft.in/api/s3Url", requestOptions)
                .then((response) => response.json())
                .then((securedURLforMini) => {
                  fetch(securedURLforMini.url, {
                    method: "PUT",
                    body: miniImage,
                    headers: {
                      'Content-Type': miniImage.type
                    }
                  }).then(miniImageResp => {
                    let miniImageUrl = securedURLforMini.url.split("?")[0];
                    //update S3 Image URL into DB
                    const raw = JSON.stringify({
                      "section_id": section_id,
                      "image_url": imageUrl,
                      "image_name": imageName,
                      "image_description": imageDescription,
                      "image_date": imageDate,
                      "designation": designation,
                      "mini_image_url": miniImageUrl,
                      "image_description_title": descriptionTitle
                    });

                    const requestOptions = {
                      method: "POST",
                      headers: {
                        "Authorization": `Bearer ${sessionStorage.getItem('accessToken')}`,
                        "Content-Type": "application/json"
                      },
                      body: raw,
                      redirect: "follow"
                    };

                    fetch("https://thestoryloft.in/api/images", requestOptions)
                      .then((response) => {
                        if (response.status === 200 || response.status === 201) {
                          fetchAllImages();
                          setSnackMessage("Created successful!");
                        } else {
                          setSnackMessage("Creation failed!");
                        }
                        setOpenSnackbar(true);
                      })
                      .catch((error) => {
                        console.error(error);
                        setSnackMessage("Deletion failed!");
                        setOpenSnackbar(true);
                      });
                  })
                })
            })
            .catch((error) => console.error(error))
        })
        .catch((error) => console.error(error))
    }
    else {
      fetch("https://thestoryloft.in/api/s3Url", requestOptions)
        .then((response) => response.json())
        .then((securedURL) => {
          console.log(securedURL, "securedURL");
          // 2.Upload into s3
          fetch(securedURL.url, {
            method: "PUT",
            body: image,
            headers: {
              'Content-Type': image.type
            }
          })
            .then(resp => {
              console.log(resp, "S3");
              let imageUrl = securedURL.url.split("?")[0];
              //update S3 Image URL into DB
              const raw = JSON.stringify({
                "section_id": section_id,
                "image_url": imageUrl,
                "image_name": imageName,
                "image_description": imageDescription,
                "image_date": imageDate,
                "designation": designation
              });

              const requestOptions = {
                method: "POST",
                headers: {
                  "Authorization": `Bearer ${sessionStorage.getItem('accessToken')}`,
                  "Content-Type": "application/json"
                },
                body: raw,
                redirect: "follow"
              };

              fetch("https://thestoryloft.in/api/images", requestOptions)
                .then((response) => {
                  if (response.status === 200 || response.status === 201) {
                    fetchAllImages();
                    setSnackMessage("Created successful!");
                  } else {
                    setSnackMessage("Creation failed!");
                  }
                  setOpenSnackbar(true);
                })
                .catch((error) => {
                  console.error(error);
                  setSnackMessage("Deletion failed!");
                  setOpenSnackbar(true);
                });
            })
            .catch((error) => console.error(error))
        })
        .catch((error) => console.error(error))
    }

  }

  const handleEdit = (image, imageName, selectedImage, imageDescription, imageDate, isImageChanged, designation, miniImage, descriptionTitle) => {
    setLoading(true);
    let requestOptions = {
      method: "GET",
      redirect: "follow"
    };
    if (isImageChanged) {
      //1.get secure URL
      fetch(`https://thestoryloft.in/api/s3Url?imageName=${selectedImage["image_url"].split("amazonaws.com/")[1]}`, requestOptions)
        .then((response) => response.json())
        .then((securedURL) => {
          console.log(securedURL, "securedURL");
          // 2.Upload into s3
          fetch(securedURL.url, {
            method: "PUT",
            body: image,
            headers: {
              'Content-Type': image.type
            }
          })
            .then(resp => {
              console.log(resp, "S3");
              let imageUrl = securedURL.url.split("?")[0];
              //update S3 Image URL into DB
              const raw = JSON.stringify({
                "section_id": section_id,
                "image_url": imageUrl,
                "image_name": imageName,
                "image_description": imageDescription,
                "image_date": imageDate,
                "designation": designation,
                "image_description_title": descriptionTitle
              });

              const requestOptions = {
                method: "PUT",
                headers: {
                  "Authorization": `Bearer ${sessionStorage.getItem('accessToken')}`,
                  "Content-Type": "application/json"
                },
                body: raw,
                redirect: "follow"
              };

              fetch("https://thestoryloft.in/api/images/" + selectedImage["image_id"], requestOptions)
                .then(async (response) => {
                  if (response.status === 200 || response.status === 201) {
                    fetchAllImages();
                    setSnackMessage("Edited successful!");
                  } else {
                    let result = await response.text()
                    setSnackMessage("Edition failed! with : " + result);
                  }
                  setOpenSnackbar(true);
                })
                .catch((error) => {
                  console.error(error);
                  setSnackMessage("Edition failed!");
                  setOpenSnackbar(true);
                });
            })
            .catch((error) => console.error(error))
        })
        .catch((error) => console.error(error))
    }
    else {
      //update into DB
      const raw = JSON.stringify({
        "section_id": section_id,
        "image_url": selectedImage["image_url"],
        "image_name": imageName,
        "image_description": imageDescription,
        "image_date": imageDate,
        "designation": designation
      });

      const requestOptions = {
        method: "PUT",
        headers: {
          "Authorization": `Bearer ${sessionStorage.getItem('accessToken')}`,
          "Content-Type": "application/json"
        },
        body: raw,
        redirect: "follow"
      };

      fetch("https://thestoryloft.in/api/images/" + selectedImage["image_id"], requestOptions)
        .then(async (response) => {
          if (response.status === 200 || response.status === 201) {
            fetchAllImages();
            setSnackMessage("Edited successful!");
          } else {
            let result = await response.text()
            setSnackMessage("Edition failed! with : " + result);
          }
          setOpenSnackbar(true);
        })
        .catch((error) => {
          console.error(error);
          setSnackMessage("Edition failed!");
          setOpenSnackbar(true);
        });
    }

  }

  const handleOpenImageDialog = (image) => {
    setOpenImageDialog(true);
    setSelectedImage(image);
  }

  const handleOpenAddDialog = () => {
    setSelectedImage({})
    setOpenImageDialog(true);
  }

  const backgroundColor = /fail/.test(snackMessage) ? '#c62828' : '#00b6b5';
  return (
    <div className="portfolio-container">
      <div className="header">
        <h1>{title}</h1>
        <Button variant="contained" onClick={handleOpenAddDialog} className='addButton' >Add</Button>
      </div>
      {loading ? ( // Show CircularProgress while loading
        <div className="loader-container">
          <CircularProgress />
        </div>
      ) : (
        <div className="full-gallery">
          <div className="image-gallery">
            {images.map((image, index) => (
              <Droppable
                key={index}
                types={['image']}
                onDrop={(data) => onDrop(data, index)}
              >
                <Draggable type="image" data={index.toString()}>
                  <div className={isNewsAndUpdate ? "newsgallery-item gallery-item" : "gallery-item"}>
                    <img src={imagesbase64[index]} alt={image.name} />
                    <div className="item-overlay">
                      <div className="overlay-buttons">
                       <Button onClick={() => handleOpenImageDialog(image)}>
                          {isBrandCommunication?<VisibilityIcon sx={{ color: "white" }} />:<EditIcon sx={{ color: "white" }} />}
                        </Button>
                        <Button onClick={() => handleOpenDialog(image)}>
                          <DeleteIcon sx={{ color: "white" }} />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Draggable>
              </Droppable>
            ))}
          </div>
        </div>
      )}
      <AlertDialog open={openDialog} handleClose={handleCloseDialog} handleDelete={handleDelete} />
      <ImageDetailsDialog isBrandCommunication={isBrandCommunication} hasMiniImage={hasMiniImage} hasDescriptionTitle={hasDescriptionTitle} hasDescription={hasDescription} hasDesignation={hasDesignation} open={openImageDialog} hasDate={hasDate} isNewsAndUpdate={isNewsAndUpdate} handleClose={handleCloseImageDialog} handleSubmit={selectedImage && selectedImage["image_url"] ? handleEdit : handleAdd} selectedImage={selectedImage} />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        message={snackMessage}
        onClose={() => setOpenSnackbar(false)}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: backgroundColor,
          },
        }}
      />
    </div>
  );
};

export default Portfolio;
