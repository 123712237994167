import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router for navigation
import "./dashboard.css"
const Dashboard = () => {
  return (
    <div className="dashboard">
      <header className="header">
        <h1>Dashboard</h1>
      </header>
      <div className="card-container">
        <Link to="/portfolio" className="card">
          <div className="card-content">
            <h2>Portfolio</h2>
          </div>
        </Link>
        <Link to="/clients" className="card">
          <div className="card-content">
            <h2>Clients</h2>
          </div>
        </Link>
        <Link to="/testimonial" className="card">
          <div className="card-content">
            <h2>Testimonials</h2>
          </div>
        </Link>
        <Link to="/learningAndDevelopmentText" className="card">
          <div className="card-content">
            <h2>Learning & Development Summary</h2>
          </div>
        </Link>
        <Link to="/learningAndDevelopment" className="card">
          <div className="card-content">
            <h2>Learning & Development</h2>
          </div>
        </Link>        
        <Link to="/performances" className="card">
          <div className="card-content">
            <h2>Performances</h2>
          </div>
        </Link>
        <Link to="/fineArt" className="card">
          <div className="card-content">
            <h2>Fine Art</h2>
          </div>
        </Link>
        <Link to="/news" className="card">
          <div className="card-content">
            <h2>News & Updates</h2>
          </div>
        </Link>
        <Link to="/characters" className="card">
          <div className="card-content">
            <h2>Characters</h2>
          </div>
        </Link>
        <Link to="/brandCommunicationsText" className="card">
          <div className="card-content">
            <h2>Brand Communications Summary</h2>
          </div>
        </Link>
        <Link to="/brandCommunications" className="card">
          <div className="card-content">
            <h2>Brand Communications</h2>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Dashboard;

