import React, { useState } from "react";
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import "./login.css";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const [input, setInput] = useState({ email: "", password: "" });
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    const handleChange = (e) => {
        setErrorMessage("");
        setInput({ ...input, [e.target.name]: e.target.value });
    };

    const loginRequest = async () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            username: input.email,
            password: input.password,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };


        await fetch("https://thestoryloft.in/api/login", requestOptions).then(async response => {
            console.log(response, "status")
            if (response.status == 401) {
                setErrorMessage("Invalid credentials!");
            }
            else if (response.status == 200) {
                const data = await response.json(); // Wait for JSON parsing
                const accessToken = data.accessToken;

                sessionStorage.setItem('accessToken', accessToken);
                navigate('/dashboard');
            }
            else {
                setErrorMessage("An error occurred during login");
            }
        }).catch(error => {
            console.error("Error:", error);
            setErrorMessage("An error occurred during login");
        });

    };

    const Logincheck = (e) => {
        e.preventDefault();
        loginRequest();
    };

    return (
        <div className="container">
            <form onSubmit={Logincheck}>
                <h1>LOG IN</h1>
                <div className="input-box">
                    <input
                        type="text"
                        name="email"
                        placeholder="UserName"
                        required
                        onChange={handleChange}
                    />
                    <PersonIcon className="icon" />
                </div>

                <div className="input-box">
                    <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        required
                        onChange={handleChange}
                    />
                    <LockIcon className="icon" />
                </div>
                {errorMessage && (
                    <div style={{ fontSize: "15px", marginLeft: "10px", color: "red", textAlign: "start", marginBottom: "10px" }}>
                        {errorMessage}
                    </div>
                )}
                <button type="submit" className="loginButton">LOGIN</button>

            </form>
        </div>
    );
};

export default Login;
