import Input from '@mui/material/Input';
import { useEffect, useState } from 'react';
import { Button, CircularProgress } from '@mui/material'; // Import CircularProgress from MUI
import Snackbar from '@mui/material/Snackbar';
import "./learningAndDevelopmentText.css";

const LearningAndDevelopment = ({ title,section_id }) => {
  const [disabled, setDisabled] = useState(true);
  const [content, setContent] = useState("");
  const [contentId, setContentId] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [loading, setLoading] = useState(true); // State for loading indicator

  useEffect(() => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem('accessToken')}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch("https://thestoryloft.in/api/content/"+section_id, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result); setContent(result[0].content);setContentId(result[0].content_id)
      })
      .catch((error) => {
        console.error(error);
        setSnackMessage("Edition failed!");
        setOpenSnackbar(true);
      }).finally(() => {
        setLoading(false);
      });
  }, [])

  const updateContent = () => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem('accessToken')}`);

    const raw = JSON.stringify({
      "content": content
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch("https://thestoryloft.in/api/content/"+contentId, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        setSnackMessage("Edited successful!");
        setDisabled(true)
      })
      .catch((error) => {
        console.error(error);
        setSnackMessage("Edition failed!");
      }).finally(() => {
        setOpenSnackbar(true);
        setLoading(false);
      });
  }

  const handleClick = () => {
    if (disabled) {
      setDisabled(false);
    }
    else {
      updateContent();
    }
  }
  const backgroundColor = /fail/.test(snackMessage) ? '#c62828' : '#00b6b5';
  return (
    <div className="portfolio-container">
      <div className="header">
        <h1>{title}</h1>
        <div>
        {!disabled?<Button variant="contained" onClick={()=>setDisabled(true)} color="error" sx={{ marginRight: "10px" }} >Cancel</Button>:null}
        <Button variant="contained" onClick={handleClick} className='addButton' >{disabled ? "Update" : "Save"}</Button>
        </div>
      </div>
      <div style={{display:"flex",justifyContent:"center"}}>
      <div style={{marginTop:"50px",width:"80%"}}>
        <Input disabled={disabled} variant="outline" value={content} onChange={(e) => setContent(e.target.value)} fullWidth maxRows={10} multiline />
      </div>
      </div>
      {loading ? ( // Show CircularProgress while loading
        <div className="ld-loader-container">
          <CircularProgress />
        </div>
      ) : <div></div>
      }
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        message={snackMessage}
        onClose={() => setOpenSnackbar(false)}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: backgroundColor,
          },
        }}
      />
    </div>
  )
}

export default LearningAndDevelopment;