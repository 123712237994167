import './App.css';
import Portfolio from "./Portfolio/portfolio";
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login/login';
import Dashboard from './Dashboard/dashboard';
import Testimonial from './Testimonial/testimonial';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import LearningAndDevelopmenttext from './LearningAndDevelopmentText/learningAndDevelopmentText';

const theme = createTheme({
  palette: {
    primary: {
      main: "#00b6b5",
      contrastText:"white"
    }
  },
});
function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/portfolio" element={<Portfolio title={"Portfolio"} section_id={"20"} />} />
            <Route path="/clients" element={<Portfolio title={"Clients"} section_id={"21"} />} />
            <Route path="/testimonial" element={<Testimonial title={"Testimonials"} section_id={"22"} />} />
            <Route path="/learningAndDevelopment" element={<Portfolio title={"Learning And Development"} section_id={"23"} />} />
            <Route path="/learningAndDevelopmenttext" element={<LearningAndDevelopmenttext title={"Learning And Development"} section_id={"27"} />} />
            <Route path="/brandCommunicationsText" element={<LearningAndDevelopmenttext title={"Brand Communications"} section_id={"29"} />} />
            <Route path="/brandCommunications" element={<Portfolio title={"Brand Communications"} section_id={"30"} hasDescription={true} hasDescriptionTitle={true} hasMiniImage={true} isBrandCommunication={true}/>} />
            <Route path="/performances" element={<Portfolio title={"Performances"} section_id={"24"} hasDescription={false} />} />
            <Route path="/fineArt" element={<Portfolio title={"Fine Art"} section_id={"25"} hasDescription={false} />} />
            <Route path="/news" element={<Portfolio title={"News & Updates"} section_id={"26"} hasDescription={true} hasDate={true} isNewsAndUpdate={true}/>} />
            <Route path="/characters" element={<Portfolio title={"Characters"} section_id={"28"} hasDescription={true} hasDesignation={true}/>} />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
