import * as React from 'react';
import {useRef} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import "./addDialog.css";
import moment from 'moment';

function getFileIdFromUrl(url) {
    try {
        const startIdx = url.indexOf('/d/') + 3;
        if (startIdx !== -1) {
            const endIdx = url.indexOf('/', startIdx);
            if (endIdx !== -1) {
                return url.substring(startIdx, endIdx);
            } else {
                return url.substring(startIdx);
            }
        }
        return null;
    } catch (error) {
        console.error('Error extracting file ID:', error);
        return null;
    }
}

var isImageChanged = false, isMiniImageChanged = false;
export default function ImageDetailsDialog(props) {
    const { open, handleClose, handleSubmit, selectedImage, hasDescription, hasDate, isNewsAndUpdate, hasDesignation, isBrandCommunication, hasMiniImage, hasDescriptionTitle } = props;
    console.log(selectedImage, "selectedImage");
    const contentRef = useRef(null);
    const [imageName, setImageName] = React.useState(selectedImage ? selectedImage.image_name : '');
    const [image, setImage] = React.useState(selectedImage ? selectedImage.image_url : '');
    const [imagePreview, setImagePreview] = React.useState(selectedImage ? selectedImage.image_url : '');
    const [miniImage, setMiniImage] = React.useState(selectedImage ? selectedImage.mini_image_url : '');
    const [miniImagePreview, setMiniImagePreview] = React.useState(selectedImage ? selectedImage.mini_image_url : '');
    const [imageDescription, setImageDescription] = React.useState(selectedImage ? selectedImage.image_description : '');
    const [imageDate, setImageDate] = React.useState(selectedImage ? moment(selectedImage.image_date) : '');
    const [designation, setDesignation] = React.useState(selectedImage ? selectedImage.designation : '');
    const [imageDescriptionTitle, setImageDescriptionTitle] = React.useState(selectedImage ? selectedImage.image_description_title : '');

    React.useEffect(() => {
        setImage(selectedImage ? selectedImage.image_url || '' : '');
        setMiniImage(selectedImage ? selectedImage.mini_image_url || '' : '');
        setImageName(selectedImage ? selectedImage.image_name || '' : '');
        setImagePreview(selectedImage ? selectedImage.image_url || '' : '');
        setMiniImagePreview(selectedImage ? selectedImage.mini_image_url || '' : '');
        setImageDescription(selectedImage ? selectedImage.image_description || '' : '');
        setImageDate(selectedImage ? moment(selectedImage.image_date) || '' : '');
        setDesignation(selectedImage ? selectedImage.designation || '' : '');
        setImageDescriptionTitle(selectedImage ? selectedImage.image_description_title || '' : '');

        return () => {
            isImageChanged = false;
            isMiniImageChanged = false;
        }
    }, [selectedImage]);

    const handleUploadClick = (event) => {
        var file = event.target.files[0];
        setImage(file);
        isImageChanged = true;
        const reader = new FileReader();

        reader.onloadend = function (e) {
            setImagePreview(reader.result);
        };

        reader.readAsDataURL(file);
        console.log(contentRef,"contentRef")
        setTimeout(()=>{
            if (contentRef.current) {
                contentRef.current.scrollTop = contentRef.current.scrollHeight+500;
              }
        },100)
        
    };

    const handleMiniImageUploadClick = (event) => {
        var file = event.target.files[0];
        setMiniImage(file);
        isMiniImageChanged = true;
        const reader = new FileReader();

        reader.onloadend = function (e) {
            setMiniImagePreview(reader.result);
        };

        reader.readAsDataURL(file);
    };

    const handleImageNameChange = (event) => {
        setImageName(event.target.value);
    };

    const handleDesignationChange = (event) => {
        setDesignation(event.target.value);
    }

    const handleImageDescriptionChange = (event) => {
        setImageDescription(event.target.value);
    };

    const handleImageDescriptionTitleChange = (event) => {
        setImageDescriptionTitle(event.target.value);
    };

    const handleImageDateChange = (event) => {
        console.log(event);
        setImageDate(event);
    }

    const handleFormSubmit = () => {
        handleSubmit(
            image,
            imageName, selectedImage, imageDescription, imageDate.valueOf(), isImageChanged, designation, miniImage, imageDescriptionTitle
        );

        setImage('');
        setImageName('');
        setImageDescription('');
        setImageDate('');
        setDesignation('');
        setImageDescriptionTitle('');
        setMiniImage('');
        setMiniImagePreview('');
        setImagePreview('');
        handleClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="image-details-dialog-title"
            aria-describedby="image-details-dialog-description"
            fullWidth={true}
            maxWidth="sm" // Adjust size as needed
        >
            <DialogTitle id="image-details-dialog-title">{hasDesignation ? "Character Details" : "Image Details"}</DialogTitle>
            <DialogContent ref={contentRef}>
                <TextField
                    margin="dense"
                    id="image-name"
                    label={hasDesignation ? "Name" : "Image Name"}
                    type="text"
                    fullWidth
                    value={imageName}
                    onChange={handleImageNameChange}
                />
                {hasDesignation &&
                    <div style={{ marginTop: "12px" }}>
                        <TextField
                            margin="dense"
                            id="image-designation"
                            label="Designation"
                            type="text"
                            fullWidth
                            value={designation}
                            onChange={handleDesignationChange}
                        />
                    </div>
                }

                {hasDate &&
                    <div className='datePickerContainer'>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker label="Date" value={imageDate ? imageDate : null} sx={{ width: "100%" }} onChange={handleImageDateChange} />
                            </DemoContainer>
                        </LocalizationProvider>
                    </div>}

                {hasDescriptionTitle && <TextField sx={{ marginTop: "20px" }}
                    margin="dense"
                    id="image-description-title"
                    label={"Description Title"}
                    type="text"
                    fullWidth
                    value={imageDescriptionTitle}
                    onChange={handleImageDescriptionTitleChange}
                />}

                {hasDescription && <TextField sx={{ marginTop: "20px" }}
                    margin="dense"
                    id="image-description"
                    label={isBrandCommunication ? "Description" : hasDesignation ? "Bio" : "Image Description"}
                    type="text"
                    multiline
                    fullWidth
                    value={imageDescription}
                    onChange={handleImageDescriptionChange}
                    rows={4} // Minimum number of rows
                    maxRows={Infinity} // Automatically adjust height
                />}

               {isBrandCommunication && <Button
                    component="label"
                    variant="contained"
                    fullWidth
                    sx={{ marginTop: "20px" }}
                >
                    {miniImage ? "Logo Replace" : "Logo Upload"}
                    <input
                        accept="image/*"
                        type="file"
                        onChange={handleMiniImageUploadClick}
                        style={{ display: 'none' }}
                    />
                </Button>}
                {isBrandCommunication && <div className="image-preview-container">
                    {miniImagePreview && (
                        <img
                            src={miniImagePreview}
                            alt="Preview"
                            className="mini-image-preview"
                        />
                    )}
                </div>}
                


                <Button
                    component="label"
                    variant="contained"
                    fullWidth
                    sx={{ marginTop: "20px" }}
                >
                    {image ? "Replace" : "Upload"}
                    <input
                        accept="image/*"
                        type="file"
                        onChange={handleUploadClick}
                        style={{ display: 'none' }}
                    />
                </Button>
                <div className="image-preview-container">
                    {imagePreview && (
                        <img
                            src={imagePreview}
                            alt="Preview"
                            className="image-preview"
                        />
                    )}
                </div>



            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleClose} color="error">
                    Cancel
                </Button>
                {isBrandCommunication && selectedImage && selectedImage["image_url"]?null:<Button variant="contained" onClick={handleFormSubmit} color="primary" disabled={!image || (isBrandCommunication ? !miniImage : true)}>
                    Submit
                </Button>}
            </DialogActions>
        </Dialog>
    );
}
