import React, { useEffect, useState } from "react";
import "./testimonial.css";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress } from '@mui/material'; // Import CircularProgress from MUI
import Snackbar from '@mui/material/Snackbar';


var isUseEffectCalled = false;
const Testimonial = ({ title, section_id }) => {
  const [testimonials, setTestimonials] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(null);
  const [testimonialData, setTestimonialData] = useState({ message: "", message_by: "" });
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [indexToDelete, setIndexToDelete] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [loading, setLoading] = useState(true); // State for loading indicator


  const fetchTestimonials = () => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem('accessToken')}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    //get image order
    fetch("https://thestoryloft.in/api/orders/" + section_id, requestOptions)
      .then((response) => response.json())
      .then((orderResult) => {
        console.log(orderResult[0].orderList, "orderList");
        fetch("https://thestoryloft.in/api/testimonial", requestOptions)
          .then((response) => response.json())
          .then((result) => {
            let sortedTestimonial = result.map(val => {
              val["order"] = orderResult[0].orderList.split(",").indexOf(val.testimonial_id.toString());
              if (val["order"] == -1) val["order"] = 10000;
              return val;
            }).sort((a, b) => a.order - b.order);
            setTestimonials(sortedTestimonial);
            setLoading(false); // Set loading to false after data is fetched
          }
          )
          .catch((error) => {
            console.error(error);
            setLoading(false); // Set loading to false on error as well
          });
      })
      .catch((error) => console.error(error))
  }
  useEffect(() => {
    if (!isUseEffectCalled) {
      isUseEffectCalled = true;
      fetchTestimonials();
    }
  }, [])
  const handleClickOpen = (edit = false, index = null) => {
    if (edit) {
      setIsEditing(true);
      setCurrentTestimonialIndex(index);
      setTestimonialData(testimonials[index]);
      setOpen(true);
    } else {
      setIsEditing(false);
      setTestimonialData({ message: "", message_by: "" });
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentTestimonialIndex(null);
  };

  const handleAdd = () => {
    setLoading(true);
    handleClose();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem('accessToken')}`);

    const raw = JSON.stringify({
      "message": testimonialData.message,
      "message_by": testimonialData.message_by
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch("https://thestoryloft.in/api/testimonial", requestOptions)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          fetchTestimonials();
          setSnackMessage("Created successful!");
        } else {
          setSnackMessage("Creation failed!");
        }
        setOpenSnackbar(true);
      })
      .catch((error) => {
        console.error(error);
        setSnackMessage("Creation failed!");
        setOpenSnackbar(true);
      });
  };

  const handleEditSave = () => {
    setLoading(true);
    handleClose();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem('accessToken')}`);

    const raw = JSON.stringify({
      "message": testimonialData.message,
      "message_by": testimonialData.message_by
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch("https://thestoryloft.in/api/testimonial/" + testimonials[currentTestimonialIndex].testimonial_id, requestOptions)
      .then(async (response) => {
        if (response.status === 200 || response.status === 201) {
          fetchTestimonials();
          setSnackMessage("Edited successful!");
        } else {
          let result = await response.text()
          setSnackMessage("Edition failed! with : " + result);
        }
        setOpenSnackbar(true);
      })
      .catch((error) => {
        console.error(error);
        setSnackMessage("Edition failed!");
        setOpenSnackbar(true);
      });
  };

  const handleDeleteConfirm = (index) => {
    setDeleteConfirmOpen(true);
    setIndexToDelete(index);
  };

  const handleDelete = () => {
    setLoading(true);
    setDeleteConfirmOpen(false);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem('accessToken')}`);

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch("https://thestoryloft.in/api/testimonial/" + testimonials[indexToDelete].testimonial_id, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          fetchTestimonials();
          setSnackMessage("Deletion successful!");
        } else {
          setSnackMessage("Deletion failed!");
        }
        setOpenSnackbar(true);
        setIndexToDelete(null);
      })
      .catch((error) => {
        console.error(error);
        setSnackMessage("Deletion failed!");
        setOpenSnackbar(true);
      });

  };

  const handleDeleteClose = () => {
    setDeleteConfirmOpen(false);
    setIndexToDelete(null);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("index", index.toString());
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, newIndex) => {
    const droppedIndex = parseInt(e.dataTransfer.getData("index"), 10);
    const items = Array.from(testimonials);
    const [draggedItem] = items.splice(droppedIndex, 1);
    items.splice(newIndex, 0, draggedItem);
    setTestimonials(items);
    const raw = JSON.stringify({
      "section_id": section_id,
      "orderList": items.map(val => val["testimonial_id"]).join(",")
    });

    const requestOptions = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${sessionStorage.getItem('accessToken')}`,
        "Content-Type": "application/json"
      },
      body: raw,
      redirect: "follow"
    };

    fetch("https://thestoryloft.in/api/orders/" + section_id, requestOptions)
      .then((response) => {
        console.log(response, "response");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const backgroundColor = /fail/.test(snackMessage) ? '#c62828' : '#00b6b5';

  return (
    <div className="portfolio-container">
      <div className="header">
        <h1>{title}</h1>
        <Button variant="contained" onClick={() => handleClickOpen()} className='addButton' >Add</Button>
      </div>

      <div className="testimonial-container">
        <div className="labels-container">
          <div className="label-comment">
            <h3>COMMENTS</h3>
          </div>
          <div className="label-by">
            <h3>Author</h3>
          </div>
        </div>
        {loading ? ( // Show CircularProgress while loading
          <div className="loader-container">
            <CircularProgress />
          </div>
        ) : (
          <div className="testimonial-list">
            {testimonials.map((testimonial, index) => (
              <div
                key={testimonial.testimonial_id}
                className="testimonial-item"
                draggable
                onDragStart={(e) => handleDragStart(e, index)}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, index)}
              >
                <DragIndicatorIcon className="drag-icon" />
                <div className="input-container">
                  <div className="comments">{testimonial.message}</div>
                  <div className="author">{testimonial.message_by}</div>
                </div>
                <div className="buttons-container">
                  <button className="edit-btn" onClick={() => handleClickOpen(true, index)}>
                    <EditIcon />
                  </button>
                  <button className="delete-btn" onClick={() => handleDeleteConfirm(index)}>
                    <DeleteIcon />
                  </button>
                </div>
              </div>
            ))}
          </div>)}
      </div>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
        <DialogTitle>{isEditing ? "Edit Testimonial" : "Add Testimonial"}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: "10px" }}>
            Please enter the comments and author for the testimonial.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Comments"
            fullWidth
            value={testimonialData ? testimonialData.message : ""}
            onChange={(e) => setTestimonialData({ ...testimonialData, message: e.target.value })}
            multiline
            rows={4}
            placeholder="Enter comments here..."
            sx={{ marginBottom: "10px" }}
          />
          <TextField
            margin="dense"
            label="Author"
            fullWidth
            value={testimonialData ? testimonialData.message_by : ""}
            onChange={(e) => setTestimonialData({ ...testimonialData, message_by: e.target.value })}
            multiline
            rows={2}
            placeholder="Enter author here..."
          />
        </DialogContent>
        <DialogActions sx={{ marginRight: "15px", marginBottom: "24px" }}>
          <Button onClick={handleClose} variant="contained" color="error" sx={{ marginRight: "10px" }}>Cancel</Button>
          {isEditing ? (
            <Button onClick={handleEditSave} variant="contained">Save</Button>
          ) : (
            <Button onClick={handleAdd} variant="contained">Add</Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog open={deleteConfirmOpen} onClose={handleDeleteClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this testimonial? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ marginRight: "15px", marginBottom: "24px" }}>
          <Button onClick={handleDeleteClose} variant="contained" color="primary" sx={{ marginRight: "10px" }}>Cancel</Button>
          <Button onClick={handleDelete} variant="contained" color="error" autoFocus>Delete</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        message={snackMessage}
        onClose={() => setOpenSnackbar(false)}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: backgroundColor,
          },
        }}
      />
    </div>
  );
};

export default Testimonial;

